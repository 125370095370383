// Initialise Slick slider

( function( $, window, document, undefined ) {

	const prevArrowHTML = `<button type="button" class="slick-prev" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M13.891 17.418c0.268 0.272 0.268 0.709 0 0.979s-0.701 0.271-0.969 0l-7.83-7.908c-0.268-0.27-0.268-0.707 0-0.979l7.83-7.908c0.268-0.27 0.701-0.27 0.969 0s0.268 0.709 0 0.979l-7.141 7.419 7.141 7.418z"></path></svg></button>`;
	const nextArrowHTML = `<button type="button" class="slick-next" aria-label="Next slide"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M13.25 10l-7.141-7.42c-0.268-0.27-0.268-0.707 0-0.979 0.268-0.27 0.701-0.27 0.969 0l7.83 7.908c0.268 0.271 0.268 0.709 0 0.979l-7.83 7.908c-0.268 0.271-0.701 0.27-0.969 0s-0.268-0.707 0-0.979l7.141-7.417z"></path></svg></button>`;

	const defaultArgs = {
		autoplay: false,
		dots: false,
		arrows: true,
		prevArrow: prevArrowHTML,
		nextArrow: nextArrowHTML,
		infinite: true,
		adaptiveHeight: false,
		draggable: false
	};

	const $imageGallery = $('.js-image-carousel');

	if ( $imageGallery.length ) {
		$imageGallery.slick( $.extend( {}, defaultArgs, {
			autoplay: true,
			autoplaySpeed: 4000,
			draggable: true
		} ) );
	} // /$imageGallery.length


	const $propertyGallery = $('.js-property-carousel');

	if ( $propertyGallery.length ) {
		$propertyGallery.slick( $.extend( {}, defaultArgs, {
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 700,
					settings: {
						slidesToShow: 1
					}
				}
			]
		} ) );
	} // /$propertyGallery.length
	
} )( jQuery, window, document );
