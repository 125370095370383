/**
 * Smooth scroll to anchors
 */
(function( $ ) {
 	
	$('[href^="#"]:not([href="#top"]').click(function(event) {
		let href = event.target.getAttribute('href');
		let target = document.querySelector(href);

		if ( target ) {
			event.preventDefault();

			target.scrollIntoView({
				behavior: 'smooth'
			});
		}
	});

})( jQuery );


/**
 * To top functionality.
 */

(function( $ ) {

	let $to_top = $('[href="#top"]');

	if ( $to_top.length ) {
		$to_top.click(function(event) {
			$('html, body').animate({
	          scrollTop: 0
	        }, 500, function() {
	        	// Switch focus to the top most link
	        	$('.site-logo').parent().focus();
	        });
		});
	}
	
})( jQuery );
